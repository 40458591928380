<template>
    <div class="WrapGuess">
        <div class="HeaderGuess">
            <div class="HeaderGuess-title">{{ $t('Customers.History') }}</div>
            <div class="HeaderGuess-actions">
                <modal
                    height="auto"
                    name="customer-record-modal"
                >
                    <div class="header-img">
                        <div>
                            <img
                                :src="customerImageInModal"
                                id="avaImg"
                                alt="Italian Trulli"
                                height="50"
                                crossorigin="anonymous"
                                @error="handleErrorLoadImage"
                            />
                        </div>
                        <div
                            class="InputCustom"
                            style="cursor: pointer"
                            v-if="newCustomerModal === false"
                        >
                            <span class="material-icons">add</span>
                            <input
                                type="file"
                                @change="handleFileUpload($event)"
                            />
                            {{ $t('Customers.AddPicture') }}
                        </div>
                    </div>
                    <div class="sectionPogup">
                        <div class="wrapSelectNamePhone">
                            <div class="SelectName">
                                <span class="LabelInput">{{ $t('Customers.FullName') }}</span>
                                <input
                                    type="text"
                                    :placeholder="$t('Customers.TypeName')"
                                    class="InputCustomPogup mt-8px"
                                    v-model="customerNameInModal"
                                />
                            </div>
                            <div class="SelectPhone">
                                <span class="LabelInput">{{ $t('Customers.Phone') }}</span>
                                <input
                                    type="text"
                                    :placeholder="$t('Customers.TypeTelephone')"
                                    class="InputCustomPogup mt-8px"
                                    v-model="customerTelephoneInModal"
                                />
                            </div>
                        </div>
                        <div class="wrapSelectNamePhone mt-16">
                            <div class="SelectName">
                                <span class="LabelInput">{{ $t('Customers.Gender') }}</span>
                                <div
                                    class="SelectCustomPogup mt-8px"
                                    @click="chooseGenderOptionInModal()"
                                    v-click-outside="handleClickOutSideGenderInModal"
                                >
                                    {{ genderTitleInModal }}
                                    <span class="material-icons">arrow_drop_down</span>
                                    <div
                                        ref="wrapGenderOption"
                                        class="wrapOption"
                                    >
                                        <div
                                            class="Option"
                                            @click="changeGenderInModal('Male', 'Nam')"
                                        >
                                            {{ $t('dashboard.Male') }}
                                        </div>
                                        <div
                                            class="Option"
                                            @click="changeGenderInModal('Female', 'Nữ')"
                                        >
                                            {{ $t('dashboard.Female') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="SelectPhone">
                                <span class="LabelInput">{{ $t('Customers.DateOfBirth') }}</span>
                                <date-picker
                                    @click.native="chooseTime()"
                                    class="mt-8px"
                                    v-model="customerAgeInModal"
                                    :placeholder="customerAgeInModal"
                                    :lang="datePickerLang === 'vn' ? 'vi' : 'en'"
                                    v-click-outside="handleClickOutsideRangePicker"
                                    format="DD/MM/YYYY"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="wrapSelectNamePhone mt-16">
                            <div class="SelectName width100">
                                <span class="LabelInput">{{ $t('Customers.Segment') }}</span>
                                <div
                                    class="SelectCustomPogup mt-8px"
                                    @click="chooseCustomerTypeOptionInModal()"
                                    v-click-outside="handleClickOutSideCustomerTypeInModal"
                                >
                                    {{ customerTypeTitleInModal }}
                                    <span class="material-icons">arrow_drop_down</span>
                                    <div
                                        ref="wrapCustomerTypeOption"
                                        class="wrapOptionSegment"
                                    >
                                        <div
                                            v-for="customerType in listCustomerType"
                                            :key="customerType.id"
                                        >
                                            <div
                                                :ref="customerType.id"
                                                class="Option"
                                                @click="changeCustomerTypeInModal(customerType.name, customerType.id)"
                                            >
                                                {{ customerType.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="linkSeeHistory"
                        @click="callAPIGetCustomerHistory()"
                        v-if="newCustomerModal === false && back === false"
                    >
                        {{ $t('Customers.ShowHistory') }}
                    </div>
                    <div class="BottomPogupReport">
                        <div
                            class="Btn-Close mr-16"
                            @click="closeModal()"
                        >
                            {{ $t('Settings.Cancel') }}
                        </div>
                        <div
                            class="Btn-Save"
                            @click="changeCustomerInfo()"
                        >
                            {{ $t('Customers.Submit') }}
                        </div>
                    </div>
                </modal>
                <div
                    class="btn-export-csv"
                    v-if="back"
                    @click="comebackToCustomerHistory()"
                    ref="comebackToCustomerHistory"
                    v-click-outside=""
                >
                    {{ $t('Employee.Back') }}
                </div>
            </div>
        </div>
        <div class="WrapSectionHistory">
            <div class="WrapBtn">
                <div
                    class="btn-search"
                    v-show="back === false"
                >
                    <span class="material-icons">search</span>
                    <input
                        type="text"
                        :placeholder="$t('Customers.SearchHistory')"
                        v-model="searchValue"
                        v-on:keyup.enter="searchForCustomerHistory()"
                    />
                </div>
                <date-picker
                    v-show="back === false"
                    @click.native="chooseTime()"
                    class="widthauto timepicker"
                    v-model="rangeTime"
                    :placeholder="rangeTimeString[0] + ' ~ ' + rangeTimeString[1]"
                    range
                    :open="showRangePicker"
                    :lang="datePickerLang"
                    v-click-outside="handleClickOutsideRangePicker"
                >
                </date-picker>
                <div
                    class="btn-filter mr-16 ml-32"
                    v-bind:class="{
                        ActiveButton: filterMode,
                        'btn-filter': !filterMode,
                    }"
                    v-show="back === false"
                    @click="turnFilter()"
                >
                    <span class="material-icons">filter_alt</span>
                    Filter
                </div>
                <div
                    class="btn-filter ml-auto"
                    v-show="back === false"
                    @click="downloadReportExcel()"
                >
                    <span class="material-icons">download</span>
                    {{ $t('Customers.ExportHistory') }}
                </div>
            </div>
            <div
                class="SelectFilter"
                v-show="filterMode === true && specifiedCustomerHistory === false"
            >
                <div
                    class="selectCustom mr-16"
                    @click="chooseGroupOption()"
                    v-click-outside="handleClickOutSideGroup"
                    v-bind:class="{ ActiveButton: storeAvailable }"
                >
                    <span class="Texoverflow">
                        {{ groupDisplay || groupFilterResult }}
                    </span>
                    <span class="material-icons">arrow_drop_down</span>
                    <div
                        class="wrapOption"
                        ref="wrapGroupOption"
                    >
                        <div
                            style="padding: 8px 16px"
                            v-for="(groupName, index) in listGroup"
                            :key="index"
                        >
                            <input
                                id="checkboxGroupName"
                                style="cursor: pointer; margin-right: 8px"
                                type="checkbox"
                                :checked="groupName.isChecked"
                                :value="groupName.name"
                                @click="selectGroupNameData(groupName.id)"
                            />
                            {{ groupName.name }}
                        </div>
                    </div>
                </div>
                <div
                    class="selectCustom"
                    @click="chooseCustomerTypeFilter()"
                    v-click-outside="handleClickOutsideCustomerTypeFilter"
                    v-bind:class="{ ActiveButton: segmentAvailable }"
                >
                    <span class="Texoverflow">
                        {{ customerTypeDisplay || customerTypeFilterResult }}
                    </span>
                    <span class="material-icons">arrow_drop_down</span>
                    <div
                        ref="wrapCustomerTypeFilterOption"
                        class="wrapOption"
                    >
                        <div
                            style="padding: 8px 16px; display: flex"
                            v-for="(cusType, index) in listCustomerType"
                            :key="index"
                            @click="selectCustomerTypeData(cusType.id)"
                        >
                            <input
                                id="checkboxCustomerSegment"
                                style="cursor: pointer; margin-right: 8px"
                                type="checkbox"
                                :checked="cusType.isChecked"
                                :value="cusType.name"
                            />
                            {{ cusType.name }}
                        </div>
                    </div>
                </div>
                <div class="CloserFilter2">
                    {{ this.$t('Customers.ImageAvailability') }}
                    <toggle-button
                        :value="filterIsMaskedFace"
                        :labels="{ checked: '', unchecked: '' }"
                        @change="filterIsMaskedFace = !filterIsMaskedFace"
                        style="margin-left: 10px; color: #4caf50"
                    />
                </div>
                <div class="CloserFilter">
                    <span
                        class="material-icons"
                        @click="resetFilter()"
                        >filter_alt_off</span
                    >
                </div>
            </div>
            <div class="WrapTableCustomerHistory">
                <table id="customers">
                    <tr class="tr-head">
                        <th>{{ this.$t('Customers.FullName') }}</th>
                        <th>Live</th>
                        <th
                            v-if="back === false"
                            style="cursor: pointer"
                            @click="changeSortTime(!ascendingTimeValue)"
                        >
                            {{ this.$t('Customers.Time') }}
                            <span
                                class="material-icons"
                                v-show="back === false"
                                v-if="ascendingTimeValue === true && back === false"
                                >arrow_drop_up</span
                            >
                            <span
                                class="material-icons"
                                v-show="back === false"
                                v-else
                                >arrow_drop_down</span
                            >
                        </th>
                        <th v-else>{{ this.$t('Customers.Time') }}</th>
                        <th>{{ this.$t('Customers.StoreAddress') }}</th>
                        <th>{{ this.$t('Customers.Segment') }}</th>
                        <th>{{ back === false ? this.$t('Customers.Availability') : this.$t('') }}</th>
                    </tr>
                    <tr
                        v-for="customerRecord in listCustomerRecordData"
                        :key="customerRecord.id"
                    >
                        <div
                            v-if="back === true"
                            style="cursor: default; pointer-events: none"
                            class="border-bottom border-left"
                        >
                            <td
                                v-if="customerRecord.isMaskedFace === false"
                                style="cursor: default"
                                class="border-bottom border-left"
                            >
                                <img
                                    :src="customerRecord.image"
                                    alt="Italian Trulli"
                                    @error="handleErrorLoadImage"
                                />
                                {{ getCustomerName(customerRecord.customerName, customerRecord.newCustomer) }}
                            </td>
                            <td
                                v-else
                                @click="
                                    showEditCustomerRecordModal(
                                        customerRecord.timestamp,
                                        customerRecord.groupName,
                                        customerRecord.id,
                                        customerRecord.customerID,
                                        customerRecord.currentImage,
                                        customerRecord.image,
                                        customerRecord.customerName
                                    )
                                "
                                style="cursor: default"
                                class="border-bottom border-left"
                            >
                                <img
                                    :src="customerRecord.image"
                                    alt="Image"
                                    @error="handleErrorLoadImage"
                                />
                                {{ getCustomerName(customerRecord.customerName, customerRecord.newCustomer) }}
                            </td>
                        </div>
                        <div v-else>
                            <td
                                v-if="customerRecord.isMaskedFace === false"
                                style="cursor: default"
                                class="border-bottom border-left"
                            >
                                <img
                                    :src="customerRecord.image"
                                    alt="Italian Trulli"
                                    @error="handleErrorLoadImage"
                                />
                                {{ getCustomerName(customerRecord.customerName, customerRecord.newCustomer) }}
                            </td>
                            <td
                                v-else
                                @click="
                                    showEditCustomerRecordModal(
                                        customerRecord.timestamp,
                                        customerRecord.groupName,
                                        customerRecord.id,
                                        customerRecord.customerID,
                                        customerRecord.currentImage,
                                        customerRecord.image,
                                        customerRecord.customerName
                                    )
                                "
                                class="border-bottom border-left"
                            >
                                <img
                                    :src="customerRecord.image"
                                    alt="Italian Trulli"
                                    @error="handleErrorLoadImage"
                                />
                                {{ getCustomerName(customerRecord.customerName, customerRecord.newCustomer) }}
                            </td>
                        </div>

                        <td class="border-bottom">
                            <img
                                :src="customerRecord.currentImage"
                                alt="Italian Trulli"
                                @error="handleErrorLoadImage"
                            />
                        </td>
                        <td class="border-bottom">{{ convertTimestampToString(customerRecord.timeVisit) }}</td>
                        <td class="border-bottom">{{ customerRecord.groupName }}</td>
                        <td class="border-bottom">{{ customerRecord.customerType }}</td>
                        <td class="border-bottom border-right">
                            <span
                                style="color: green; cursor: default"
                                class="material-icons"
                                v-if="customerRecord['isMaskedFace'] && customerRecord['customerID'] === ''"
                                >done</span
                            >
                            <span
                                style="color: red; cursor: default"
                                class="material-icons"
                                v-if="!customerRecord['isMaskedFace'] && customerRecord['customerID'] === ''"
                                >close</span
                            >
                        </td>
                    </tr>
                </table>

                <div class="pagination">
                    <div class="wrapSelectPagination">
                        <span class="seeTable"> {{ $t('Customers.RowPerPage') }}</span>
                        <div
                            class="SelectPagination"
                            @click="chooseLimitFilter()"
                            v-click-outside="handleClickOutsideLimitFilter"
                        >
                            {{ limit }}
                            <span class="material-icons">arrow_drop_down</span>
                            <div
                                class="wrapOptionPagination"
                                ref="wrapLimitFilterOption"
                            >
                                <div
                                    class="OptionActive"
                                    ref="limitFive"
                                    @click="changeLimit('5', 'limitFive')"
                                >
                                    5
                                </div>
                                <div
                                    class="Option"
                                    ref="limitTen"
                                    @click="changeLimit('10', 'limitTen')"
                                >
                                    10
                                </div>
                                <div
                                    class="Option"
                                    ref="limitFifteen"
                                    @click="changeLimit('15', 'limitFifteen')"
                                >
                                    15
                                </div>
                                <div
                                    class="Option"
                                    ref="limitTwenty"
                                    @click="changeLimit('20', 'limitTwenty')"
                                >
                                    20
                                </div>
                            </div>
                        </div>
                    </div>
                    <a
                        style="cursor: pointer"
                        v-show="page !== 1"
                        @click="changePage(0)"
                    >
                        <span class="material-icons">first_page</span>
                    </a>
                    <a
                        style="cursor: pointer"
                        v-show="page !== 1"
                        @click="changePage(-1)"
                        ><span class="material-icons">navigate_before</span>
                    </a>
                    <a
                        style="cursor: pointer"
                        @click="changePage(-1)"
                        v-if="page > 1"
                        >{{ page - 1 }}</a
                    >
                    <a
                        style="cursor: pointer"
                        class="active"
                        >{{ page }}</a
                    >
                    <a
                        style="cursor: pointer"
                        @click="changePage(1)"
                        v-if="page < totalPage"
                        >{{ parseInt(page) + 1 }}</a
                    >
                    <a
                        style="cursor: pointer"
                        v-show="page < totalPage"
                        @click="changePage(1)"
                    >
                        <span class="material-icons">navigate_next</span>
                    </a>
                    <a
                        style="cursor: pointer"
                        v-show="page < totalPage"
                        @click="changePage(parseInt(totalPage))"
                    >
                        <span class="material-icons">last_page</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import 'moment/locale/vi';
import { Api } from '@/api';
import { ToggleButton } from 'vue-js-toggle-button';

const listCustomerHistoryAPI = Api.get('getCustomerRecordV2');
const getAllGroupCurrentUser = Api.get('getAllGroupCurrentUser');
const listCustomerTypeAPI = Api.get('listCustomerType');
const getCustomerRecordDetail = Api.get('getCustomerDetailV2');
const addCustomerOtherWay = Api.get('addCustomerOtherWay');
const customerHistoryAPI = Api.get('customerHistory');
const updateCustomerAPI = Api.get('updateCustomerV2');
const downloadReportExcelAPI = Api.get('exportExcelHistoryCustomer');
const addCustomer = Api.get('addCustomerV2');

const ListDatePickerClass = [
    'option rangeTime',
    'mx-icon-right',
    'mx-icon-left',
    'mx-icon-double-left',
    'mx-icon-double-right',
    'mx-calendar-header',
    'mx-btn mx-btn-text mx-btn-icon-double-left',
    'mx-btn mx-btn-text mx-btn-icon-double-right',
    'mx-btn mx-btn-text mx-btn-icon-left',
    'mx-btn mx-btn-text mx-btn-icon-right',
    'mx-calendar mx-calendar-panel-date',
];

moment.tz.setDefault('Asia/Ho_Chi_Minh');
moment.locale('vi');
export default {
    name: 'CustomerHistory',
    components: {
        ToggleButton,
    },
    methods: {
        handleErrorLoadImage(e) {
            e.target.src = '/img/default-avatar.png';
        },
        getCustomerName(customerName, isNewCustomer) {
            if (isNewCustomer) {
                return localStorage.lang === 'vn' ? 'Khách mới' : 'New customer';
            }
            return customerName;
        },
        convertTimestampToString(timestamp) {
            const lang = localStorage.lang;
            const dayOfWeek = {
                1: lang === 'vn' ? 'Thứ 2 ngày' : 'Monday',
                2: lang === 'vn' ? 'Thứ 3 ngày' : 'Tuesday',
                3: lang === 'vn' ? 'Thứ 4 ngày' : 'Wednesday',
                4: lang === 'vn' ? 'Thứ 5 ngày' : 'Thursday',
                5: lang === 'vn' ? 'Thứ 6 ngày' : 'Friday',
                6: lang === 'vn' ? 'Thứ 7 ngày' : 'Saturday',
                7: lang === 'vn' ? 'Chủ nhật ngày' : 'Sunday',
            };
            const dateTimeObject = moment.unix(timestamp).locale('vi');
            return `${dateTimeObject.format('HH:mm')}, ${
                dayOfWeek[dateTimeObject.isoWeekday()]
            } ${dateTimeObject.format('DD/MM/YYYY')}`;
        },
        downloadReportExcel() {
            // if (this.listCustomerRecordData.length === 0) {
            //     this.$swal(this.$t("dashboard.NoData"), "", "warning");
            //     return;
            // }

            this.$swal({
                title: this.$t('CustomerResponse.CM99999'),
                allowOutsideClick: true,
            });
            this.$swal.showLoading();
            downloadReportExcelAPI
                .post(
                    this.dateStart,
                    this.dateEnd,
                    this.searchValue,
                    this.sortAtribute,
                    this.ascendingTimeValue,
                    this.customerTypeArr.toString(),
                    this.groupNameArr.toString(),
                    localStorage.lang
                )
                .then((response) => {
                    this.$swal.close();
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel',
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    const now = moment();
                    link.setAttribute('download', `customer_history.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$swal.close();
                    console.log('Error');
                });
        },
        comebackToCustomerHistory() {
            if (this.$route.query.report) {
                this.$router.push({
                    path: 'report-customer',
                    query: {
                        detail: true,
                    },
                });
                this.back = false;
            } else if (this.$route.query.meta === true) {
                this.$router.push({ path: 'manage-customer' });
                this.back = false;
            } else {
                let inputData = JSON.parse(localStorage.getItem('currentCustomerRecordData'));
                this.$router.replace({ query: null });
                this.back = false;
                let getOldPage = JSON.parse(localStorage.getItem('oldPage'));
                this.page = parseInt(getOldPage.page);
                this.showCustomerHistoryModal = true;
                listCustomerHistoryAPI
                    .get(
                        this.dateStart,
                        this.dateEnd,
                        this.searchValue,
                        this.sortAtribute,
                        'true',
                        this.customerTypeFilter,
                        this.groupNameFilter,
                        this.page,
                        this.limit,
                        this.filterIsMaskedFace,
                        'true'
                    )
                    .then((response) => {
                        let listCustomerRecordData = response.data['listResponse'];
                        // console.log(response)
                        // if (listCustomerRecordData.length === 0) {
                        //     this.$notify({
                        //         timeout: 2500,
                        //         message: this.$t("dashboard.NoData"),
                        //         icon: "add_alert",
                        //         horizontalAlign: "right",
                        //         verticalAlign: "top",
                        //         type: "primary",
                        //     });
                        // }
                        for (const element of listCustomerRecordData) {
                            if (element.image === '') {
                                element.image = '/img/default-avatar.png';
                            }
                            listCustomerRecordData.customerRecordEdit = false;
                        }
                        this.listCustomerRecordData = listCustomerRecordData;
                        this.totalPage = response.data.totalPage;
                        this.handleClickOutsideLimitFilter();
                        this.rangeTimeString = [
                            moment(this.dateStart * 1000).format('DD-MM-YYYY'),
                            moment(this.dateEnd * 1000).format('DD-MM-YYYY'),
                        ];
                        this.rangeTime = [
                            moment(this.dateStart * 1000).format('DD-MM-YYYY'),
                            moment(this.dateEnd * 1000).format('DD-MM-YYYY'),
                        ];
                        const self = this;
                        const lang = localStorage.lang;
                        // this.$modal.show("customer-record-modal");
                        getCustomerRecordDetail
                            .get(inputData['customerID'], lang, inputData['recordID'])
                            .then((resp) => {
                                const parseResponseData = resp.data;
                                if (parseResponseData['customerType']) {
                                    const selectedCustomerType = this.listCustomerType.find(
                                        (customerType) => customerType.id === response.data.customerType
                                    );
                                    self.customerTypeTitleInModal = parseResponseData.customerTypeName;
                                }
                                this.customerNameInModal = parseResponseData['name'];
                                if (parseResponseData['birthDate'] == null) {
                                    self.customerAgeInModal = null;
                                } else {
                                    self.customerAgeInModal = parseResponseData['birthDate'];
                                }
                                this.customerTelephoneInModal = parseResponseData['telephone'];
                                this.customerImageInModal = parseResponseData['historyImage'];
                                this.customerVisitNumberInModal = parseResponseData['visitNumber'];
                                if (parseResponseData['gender'] === 'male') {
                                    self.genderTitleInModal = this.$t('dashboard.Male');
                                } else if (parseResponseData['gender'] === 'female') {
                                    self.genderTitleInModal = this.$t('dashboard.Female');
                                } else {
                                    self.genderTitleInModal = this.$t('Customers.TypeGender');
                                }
                                // self.editingCustomer = true;
                                this.$modal.show('customer-record-modal');
                                localStorage.removeItem('currentCustomerRecordData');
                                this.back = false;
                            })
                            .then(() => {
                                fetch(this.customerImageInModal)
                                    .then((res) => res.blob())
                                    .then((blob) => {
                                        this.sendingImage = new File([blob], 'image.jpg', {
                                            type: 'image/jpg',
                                        });
                                    });
                            })
                            .catch((err) => {
                                console.log('error from get customer record by id api: ', err);
                            });
                    });
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.imageFile = file;
            this.customerImageInModal = URL.createObjectURL(file);
        },
        resetFilter() {
            this.rangeTime = [
                moment(this.dateStart * 1000).format('YYYY-MM-DD'),
                moment(this.dateEnd * 1000).format('YYYY-MM-DD'),
            ];
            this.rangeTimeString = [
                moment(this.dateStart * 1000).format('YYYY-MM-DD'),
                moment(this.dateEnd * 1000).format('YYYY-MM-DD'),
            ];
            this.groupNameFilter = '';
            this.groupFilterResult = this.$t('Report.StoreChoosing');
            this.customerTypeFilterResult = this.$t('Customers.TypeCustomerType');
            this.customerTypeFilter = '';
            this.searchValue = '';
            this.rangeTime = '';
            this.sortAtribute = 'timestamp';
            this.ascendingTimeValue = true;
            this.page = 1;
            this.specifiedCustomerHistory = false;
            this.customerTypeDisplay = '';
            this.listCustomerType = this.listCustomerType.map((customerType) => {
                customerType.isChecked = false;
                return customerType;
            });

            this.groupDisplay = '';
            this.listGroup = this.listGroup.map((group) => {
                group.isChecked = false;
                return group;
            });

            this.customerTypeArr = [];

            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                this.searchValue,
                this.sortAtribute,
                this.ascendingTimeValue,
                this.customerTypeArr.toString(),
                this.groupNameFilter,
                this.page,
                this.limit
            );
        },
        turnFilter() {
            this.filterMode = this.filterMode !== true;
            if (this.filterMode) {
                this.filterIsMaskedFace = false;
            } else {
                this.filterIsMaskedFace = '';
            }
        },
        imageToBase64(img) {
            let canvas = document.createElement('canvas');
            canvas.width = img.width + 100;
            canvas.height = img.height + 100;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL('image/png');
            this.sendingImage = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
        },
        changeCustomerInfo() {
            if (this.newCustomerModal === false) {
                const bodyFormData = new FormData();
                let birthDate = moment(this.customerAgeInModal).format('DD/MM/YYYY').toString();
                if (birthDate instanceof Date && !isNaN(birthDate)) {
                    birthDate = moment.unix(this.customerAgeInModal.getTime()).format('DD/MM/YYYY').toString();
                }
                bodyFormData.append('birthDate', birthDate);
                bodyFormData.append('gender', this.customerGenderInModal);
                bodyFormData.append('image', this.imageFile);
                bodyFormData.append('name', this.customerNameInModal);
                bodyFormData.append('customerType', this.customerTypeInModal);
                if (this.customerTelephoneInModal !== null && Boolean(this.customerTelephoneInModal) === true) {
                    bodyFormData.append('telephone', this.customerTelephoneInModal);
                }

                updateCustomerAPI
                    .put(this.customerIDInRecord, bodyFormData)
                    .then((response) => {
                        this.$swal.close();
                        this.$swal(
                            this.$t('CustomerResponse.' + response.data.message.replace('.', '')),
                            '',
                            'success'
                        ).then((_) => {
                            this.$modal.hide('customer-record-modal');
                            this.callGetListCustomerRecord(
                                this.dateStart,
                                this.dateEnd,
                                this.searchValue,
                                this.sortAtribute,
                                this.ascendingTimeValue,
                                this.customerTypeArr.toString(),
                                this.groupNameFilter,
                                this.page,
                                this.limit
                            );
                        });
                    })
                    .catch((error) => {
                        if (error.response === undefined) {
                            this.$swal.close();
                            this.$swal(this.$t('CustomerResponse.CM99998'), '', 'warning');
                        } else {
                            this.$swal.close();
                            this.$swal(
                                this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                                '',
                                'warning'
                            );
                        }
                    });
            } else {
                this.$swal({
                    title: this.$t('CustomerResponse.CM99999'),
                    allowOutsideClick: true,
                });
                this.$swal.showLoading();

                let createCustomerFormData = new FormData();
                createCustomerFormData.append(
                    'birthDate',
                    Boolean(this.customerAgeInModal)
                        ? moment(this.customerAgeInModal).format('DD/MM/YYYY').toString()
                        : '01/01/1996'
                );
                createCustomerFormData.append('gender', this.customerGenderInModal);
                createCustomerFormData.append('image', this.sendingImage);
                createCustomerFormData.append('name', this.customerNameInModal.replace(/  +/g, ' '));
                createCustomerFormData.append('customerType', this.customerTypeInModal);
                if (this.customerTelephoneInModal !== null && Boolean(this.customerTelephoneInModal) === true) {
                    createCustomerFormData.append('telephone', this.customerTelephoneInModal);
                }
                createCustomerFormData.append('visitNumber', 1);
                let customerRecordData = JSON.parse(localStorage.getItem('currentCustomerRecordData'));
                createCustomerFormData.append('historyID', customerRecordData['recordID']);
                addCustomer
                    .post(createCustomerFormData)
                    .then((_) => {
                        this.$swal.close();
                        this.$swal(this.$t('CustomerResponse.CM00020'), '', 'success');
                        this.specifiedCustomerHistory = false;
                        this.callGetListCustomerRecord(
                            this.dateStart,
                            this.dateEnd,
                            this.searchValue,
                            this.sortAtribute,
                            this.ascendingTimeValue,
                            this.customerTypeArr.toString(),
                            this.groupNameFilter,
                            this.page,
                            this.limit
                        );
                    })
                    .then((_) => {
                        this.$modal.hide('customer-record-modal');
                    })
                    .catch((error) => {
                        this.$swal(
                            this.$t('CustomerResponse.' + error.response.data.message.replace('.', '')),
                            '',
                            'warning'
                        );
                    });
            }
        },
        chooseTime() {
            this.showRangePicker = true;
        },
        searchForCustomerHistory() {
            this.page = 1;
            this.specifiedCustomerHistory = false;
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                this.searchValue,
                this.sortAtribute,
                this.ascendingTimeValue,
                this.customerTypeArr.toString(),
                this.groupNameFilter,
                this.page,
                this.limit
            );
        },
        changeSortTime(ascendingTimeValue) {
            if (this.specifiedCustomerHistory === false) {
                this.sortAtribute = 'timestamp';
                this.ascendingTimeValue = ascendingTimeValue;
                this.specifiedCustomerHistory = false;
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    this.searchValue,
                    this.sortAtribute,
                    this.ascendingTimeValue,
                    this.customerTypeArr.toString(),
                    this.groupNameFilter,
                    this.page,
                    this.limit
                );
            }
        },
        callGetListCustomerRecord(
            dateStart,
            dateEnd,
            searchValue,
            sortAtribute,
            ascending,
            customerTypeFilter,
            groupNameFilter,
            page,
            limit
        ) {
            listCustomerHistoryAPI
                .get(
                    dateStart,
                    dateEnd,
                    searchValue,
                    sortAtribute,
                    ascending,
                    customerTypeFilter,
                    groupNameFilter,
                    page,
                    limit,
                    this.filterIsMaskedFace,
                    'false'
                )
                .then((response) => {
                    let listCustomerRecordData = response.data.listResponse;
                    if (listCustomerRecordData.length === 0) {
                        this.$notify({
                            timeout: 2500,
                            message: this.$t('dashboard.NoData'),
                            icon: 'add_alert',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'primary',
                        });
                    }
                    for (let i = 0; i < listCustomerRecordData.length; i++) {
                        if (listCustomerRecordData[i].image === '') {
                            listCustomerRecordData[i].image = '/img/default-avatar.png';
                        }
                        listCustomerRecordData[i].customerRecordEdit = false;
                    }
                    this.listCustomerRecordData = listCustomerRecordData;
                    this.totalPage = response.data.totalPage;
                    this.handleClickOutsideLimitFilter();
                });
        },

        // filter methods
        handleClickOutsideRangePicker(event) {
            if (this.showRangePicker === true && !ListDatePickerClass.includes(event.srcElement._prevClass)) {
                if (event?.path?.length === 15) {
                    if (event.path[9]._prevClass !== 'mx-datepicker-content') {
                        this.showRangePicker = false;
                    }
                } else {
                    if (
                        event.target.parentElement._prevClass !== 'cell' &&
                        event.target.parentElement._prevClass !== 'cell active' &&
                        event.target.parentElement._prevClass !== 'cell today active'
                    ) {
                        this.showRangePicker = false;
                    }
                }
            }
        },
        changeGroup(groupName, groupID) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideGroup();
            }, 100);
            this.groupNameFilter = groupID === 'all' ? '' : groupName;
            this.groupFilterResult = groupName;
            this.page = 1;
            this.specifiedCustomerHistory = false;
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                this.searchValue,
                this.sortAtribute,
                this.ascendingTimeValue,
                this.customerTypeArr.toString(),
                this.groupNameFilter,
                this.page,
                this.limit,
                this.filterIsMaskedFace,
                'false'
            );
        },
        chooseGroupOption() {
            this.$refs['wrapGroupOption'].style.position = 'absolute';
            this.$refs['wrapGroupOption'].style.display = 'block';
            this.$refs['wrapGroupOption'].style.visibility = 'visible';
            this.storeAvailable = true;
        },
        handleClickOutSideGroup(index) {
            if (index) {
                this.$refs['wrapGroupOption'].style.display = 'none';
                this.$refs['wrapGroupOption'].style.visibility = 'hidden';
                this.storeAvailable = false;
                this.storeAvailable = false;
                this.storeAvailable = false;
            }
        },
        selectCustomerTypeData(customerTypeID) {
            this.listCustomerType.forEach((elm) => {
                if (elm.id === customerTypeID) {
                    elm.isChecked = !elm.isChecked;
                }
            });
            if (customerTypeID === 'Chưa có phân khúc' || customerTypeID === 'No Segment') {
                customerTypeID = '';
            }
            this.customerTypeArr.includes(customerTypeID)
                ? (this.customerTypeArr = this.customerTypeArr.filter((s) => s !== customerTypeID))
                : this.customerTypeArr.push(customerTypeID);

            let listCustomerTypeName = [];
            this.customerTypeArr.forEach((customerTypeID) => {
                const customerType = this.listCustomerType.find((customerType) => customerType.id === customerTypeID);
                listCustomerTypeName.push(customerType.name);
            });
            this.customerTypeDisplay = listCustomerTypeName.join(',');

            this.page = 1;
            let self = this;
            setTimeout(() => {
                self.handleClickOutsideCustomerTypeFilter();
            }, 100);

            this.page = 1;
            this.specifiedCustomerHistory = false;
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                this.searchValue,
                this.sortAtribute,
                this.ascendingTimeValue,
                this.customerTypeArr.toString(),
                this.groupNameArr.toString(),
                this.page,
                this.limit,
                this.filterIsMaskedFace,
                'false'
            );
        },
        selectGroupNameData(groupID) {
            if (groupID === 'All' || groupID === 'Tất cả') groupID = '';

            if (groupID === '') {
                this.listGroup[this.listGroup.findIndex((s) => s['id'] === '')]['isChecked'] = true;
                this.listGroup.map(function (elm) {
                    if (elm['id'] !== '') elm['isChecked'] = false;
                    return elm;
                });
                this.groupNameArr = [''];
            } else {
                this.listGroup[this.listGroup.findIndex((s) => s['id'] === '')]['isChecked'] = false;
                this.listGroup[this.listGroup.findIndex((s) => s['id'] === groupID)]['isChecked'] = true;
                if (this.groupNameArr.includes(groupID)) {
                    this.groupNameArr = this.groupNameArr.filter((s) => s !== groupID);
                } else {
                    this.groupNameArr = this.groupNameArr.filter((s) => s !== '');
                    this.groupNameArr.push(groupID);
                }
            }
            let listGroupName = [];
            this.groupNameArr.forEach((groupID) => {
                const group = this.listGroup.find((group) => group.id === groupID);
                listGroupName.push(group.name);
            });

            this.groupDisplay = listGroupName.join(',');

            this.page = 1;
            setTimeout(() => {
                self.handleClickOutSideGroup();
            }, 100);
            this.specifiedCustomerHistory = false;
            let self = this;
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                this.searchValue,
                this.sortAtribute,
                this.ascendingTimeValue,
                this.customerTypeArr.toString(),
                this.groupNameArr.toString(),
                this.page,
                this.limit,
                this.filterIsMaskedFace,
                'false'
            );
        },
        chooseCustomerTypeFilter() {
            this.$refs['wrapCustomerTypeFilterOption'].style.position = 'absolute';
            this.$refs['wrapCustomerTypeFilterOption'].style.display = 'block';
            this.$refs['wrapCustomerTypeFilterOption'].style.visibility = 'visible';
            this.segmentAvailable = true;
        },
        handleClickOutsideCustomerTypeFilter(index) {
            if (index) {
                this.$refs['wrapCustomerTypeFilterOption'].style.display = 'none';
                this.$refs['wrapCustomerTypeFilterOption'].style.visibility = 'hidden';
                this.segmentAvailable = false;
                this.segmentAvailable = false;
                this.segmentAvailable = false;
            }
        },

        changeLimit(number, refName) {
            this.limit = number;
            let listLimit = ['limitFive', 'limitTen', 'limitFifteen', 'limitTwenty'];
            for (let index = 0; index < listLimit.length; index++) {
                if (listLimit[index] != refName) {
                    this.$refs[listLimit[index]].classList.value = ['Option'];
                } else {
                    this.$refs[listLimit[index]].classList.value = ['OptionActive'];
                }
            }
        },
        chooseLimitFilter() {
            this.$refs['wrapLimitFilterOption'].style.position = 'absolute';
            this.$refs['wrapLimitFilterOption'].style.display = 'block';
            this.$refs['wrapLimitFilterOption'].style.visibility = 'visible';
        },
        handleClickOutsideLimitFilter() {
            this.$refs['wrapLimitFilterOption'].style.display = 'none';
            this.$refs['wrapLimitFilterOption'].style.visibility = 'hidden';
        },

        chooseGenderOptionInModal() {
            this.$refs['wrapGenderOption'].style.position = 'absolute';
            this.$refs['wrapGenderOption'].style.display = 'block';
            this.$refs['wrapGenderOption'].style.visibility = 'visible';
        },
        handleClickOutSideGenderInModal() {
            this.$refs['wrapGenderOption'].style.display = 'none';
            this.$refs['wrapGenderOption'].style.visibility = 'hidden';
        },
        changeGenderInModal(value, valueInVietNamLanguage) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideGenderInModal();
            }, 100);
            this.customerGenderInModal = value.toLowerCase();
            this.genderTitleInModal = this.$t('dashboard.' + value);
        },

        chooseCustomerTypeOptionInModal() {
            this.$refs['wrapCustomerTypeOption'].style.position = 'absolute';
            this.$refs['wrapCustomerTypeOption'].style.display = 'block';
            this.$refs['wrapCustomerTypeOption'].style.visibility = 'visible';
        },
        handleClickOutSideCustomerTypeInModal() {
            this.$refs['wrapCustomerTypeOption'].style.display = 'none';
            this.$refs['wrapCustomerTypeOption'].style.visibility = 'hidden';
        },

        changeCustomerTypeInModal(name, id) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideCustomerTypeInModal();
            }, 100);
            this.customerTypeInModal = id;
            this.customerTypeTitleInModal = name;
        },

        changePage(number) {
            if (number === 0) {
                this.page = 1;

                if (!Boolean(this.$route.query.meta) && !Boolean(this.$route.query.report) && this.back === false) {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        this.searchValue,
                        this.sortAtribute,
                        this.ascendingTimeValue,
                        this.customerTypeArr.toString(),
                        this.groupNameFilter,
                        this.page,
                        this.limit,
                        this.filterIsMaskedFace,
                        'false'
                    );
                } else {
                    customerHistoryAPI
                        .get(this.customerIDInRecord || this.$route.query.cusID, this.page, this.limit)
                        .then((response) => {
                            let listCustomerHistory = [];
                            for (let index = 0; index < response.data.historyData.length; index++) {
                                let record = {
                                    currentImage: response.data.historyData[index].currentImage,
                                    image: response.data.historyData[index].image,
                                    customerName: response.data.historyData[index].customerName,
                                    date: response.data.historyData[index].date,
                                    groupName: response.data.historyData[index].groupName,
                                    customerType: response.data.historyData[index].customerType,
                                    timeVisit: response?.data?.historyData[index]?.timeVisit,
                                };
                                listCustomerHistory.push(record);
                                this.totalPage = response.data.totalPage;
                            }
                            this.listCustomerRecordData = listCustomerHistory;
                            this.rangeTime = [];
                        })
                        .catch((error) => {
                            this.$swal(
                                this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                                '',
                                'warning'
                            );
                        });
                }
            }
            if (number === -1) {
                this.page = this.page > 1 ? this.page - 1 : this.page;
                if (!Boolean(this.$route.query.meta) && !Boolean(this.$route.query.report) && this.back === false) {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        this.searchValue,
                        this.sortAtribute,
                        this.ascendingTimeValue,
                        this.customerTypeArr.toString(),
                        this.groupNameFilter,
                        this.page,
                        this.limit,
                        this.filterIsMaskedFace,
                        'false'
                    );
                } else {
                    customerHistoryAPI
                        .get(this.customerIDInRecord || this.$route.query.cusID, this.page, this.limit)
                        .then((response) => {
                            let listCustomerHistory = [];
                            for (let index = 0; index < response.data.historyData.length; index++) {
                                let record = {
                                    currentImage: response.data.historyData[index].currentImage,
                                    image: response.data.historyData[index].image,
                                    customerName: response.data.historyData[index].customerName,
                                    date: response.data.historyData[index].date,
                                    groupName: response.data.historyData[index].groupName,
                                    customerType: response.data.historyData[index].customerType,
                                    timeVisit: response?.data?.historyData[index]?.timeVisit,
                                };
                                listCustomerHistory.push(record);
                                this.totalPage = response.data.totalPage;
                            }
                            this.listCustomerRecordData = listCustomerHistory;
                            this.rangeTime = [];
                        })
                        .catch((error) => {
                            this.$swal(
                                this.$t('CustomerResponse.' + error.response.data.message.replace('.', '')),
                                '',
                                'warning'
                            );
                        });
                }
            }
            // through to last page
            if (number === parseInt(this.totalPage)) {
                this.page = this.totalPage;
                if (!Boolean(this.$route.query.meta) && !Boolean(this.$route.query.report) && this.back === false) {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        this.searchValue,
                        this.sortAtribute,
                        this.ascendingTimeValue,
                        this.customerTypeArr.toString(),
                        this.groupNameFilter,
                        this.page,
                        this.limit,
                        this.filterIsMaskedFace,
                        'false'
                    );
                } else {
                    let customerHistoryRecordArray = [];
                    customerHistoryAPI
                        .get(this.customerIDInRecord || this.$route.query.cusID, this.page, this.limit)
                        .then((response) => {
                            for (let index = 0; index < response.data['historyData'].length; index++) {
                                customerHistoryRecordArray.push({
                                    currentImage: response.data['historyData'][index].currentImage,
                                    image: response.data.historyData[index].image,
                                    customerName: response.data.historyData[index].customerName,
                                    date: response.data['historyData'][index].date,
                                    groupName: response.data['historyData'][index].groupName,
                                    customerType: response.data['historyData'][index].customerType,
                                    timeVisit: response?.data?.historyData[index]?.timeVisit,
                                });
                            }
                            this.totalPage = response.data.totalPage;
                            this.listCustomerRecordData = customerHistoryRecordArray;
                        })
                        .catch((error) => {
                            this.$swal(
                                this.$t('CustomerResponse.' + error.response.data.message.replace('.', '')),
                                '',
                                'warning'
                            );
                        });
                }
            }
            if (number === 1) {
                this.page = this.page < this.totalPage ? this.page + 1 : this.page;
                if (!Boolean(this.$route.query.meta) && !Boolean(this.$route.query.report) && this.back === false) {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        this.searchValue,
                        this.sortAtribute,
                        this.ascendingTimeValue,
                        this.customerTypeArr.toString(),
                        this.groupNameFilter,
                        this.page,
                        this.limit,
                        this.filterIsMaskedFace,
                        'false'
                    );
                } else {
                    customerHistoryAPI
                        .get(this.customerIDInRecord || this.$route.query.cusID, this.page, this.limit)
                        .then((response) => {
                            let listCustomerHistory = [];
                            for (let index = 0; index < response.data.historyData.length; index++) {
                                let record = {
                                    currentImage: response.data.historyData[index].currentImage,
                                    image: response.data.historyData[index].image,
                                    customerName: response.data.historyData[index].customerName,
                                    date: response.data.historyData[index].date,
                                    groupName: response.data.historyData[index].groupName,
                                    customerType: response.data.historyData[index].customerType,
                                    timeVisit: response?.data?.historyData[index]?.timeVisit,
                                };
                                listCustomerHistory.push(record);
                            }
                            this.totalPage = response.data.totalPage;
                            this.listCustomerRecordData = listCustomerHistory;
                        })
                        .catch((error) => {
                            this.$swal(
                                this.$t('CustomerResponse.' + error.response.data.message.replace('.', '')),
                                '',
                                'warning'
                            );
                        });
                }
            }
        },
        callBackBehavior(val) {
            if (val === true) {
                customerHistoryAPI
                    .get(this.$route.query.cusID, this.page, this.limit)
                    .then((response) => {
                        let listCustomerHistory = [];
                        this.specifiedCustomerHistory = false;
                        for (let index = 0; index < response?.data?.historyData.length; index++) {
                            let record = {
                                currentImage: response?.data?.historyData[index]?.currentImage,
                                image: response?.data?.historyData[index]?.image,
                                customerName: response?.data?.historyData[index]?.customerName,
                                date: response?.data?.historyData[index]?.date,
                                groupName: response?.data?.historyData[index]?.groupName,
                                customerType: response?.data?.historyData[index]?.customerType,
                                isMaskedFace: response?.data?.historyData[index]?.isMaskedFace,
                                timeVisit: response?.data?.historyData[index]?.timeVisit,
                            };
                            listCustomerHistory.push(record);
                            this.totalPage = response.data.totalPage;
                        }
                        this.listCustomerRecordData = listCustomerHistory;
                        this.rangeTime = [];
                        if (response?.data?.historyData.length > 0) {
                            this.back = true;
                        }
                        if (response?.data?.historyData.length === 0) {
                            this.$notify({
                                timeout: 1300,
                                message: this.$t('dashboard.NoData'),
                                icon: 'add_alert',
                                horizontalAlign: 'right',
                                verticalAlign: 'top',
                                type: 'primary',
                            });
                            setTimeout(() => {
                                this.back = true;
                                val = false;
                            }, 1500);
                        }
                    })
                    .catch((err) => console.log('khong chay vao day: ', err));
            }
        },
        callAPIGetCustomerHistory() {
            if (!Boolean(this.$route.query.meta) && !Boolean(this.$route.query.report)) {
                this.specifiedCustomerHistory = true;
                localStorage.setItem(
                    'oldPage',
                    JSON.stringify({
                        page: this.page,
                    })
                );
                this.page = 1;
                this.specifiedCustomerHistory = true;
                customerHistoryAPI
                    .get(this.customerIDInRecord, this.page, this.limit)
                    .then((response) => {
                        this.$router.push({
                            path: 'report-face',
                            query: {
                                customerBehaviorID: this.customerIDInRecord,
                                dateStart: this.dateStart,
                                dateEnd: this.dateEnd,
                            },
                        });

                        let listCustomerHistory = response.data.historyData.map((customerHistory) => ({
                            currentImage: customerHistory.currentImage,
                            image: customerHistory.image,
                            customerName: customerHistory.customerName,
                            date: customerHistory.date,
                            groupName: customerHistory.groupName,
                            customerType: customerHistory.customerType,
                            timeVisit: customerHistory.timeVisit,
                        }));

                        this.totalPage = response.data.totalPage;
                        this.rangeTime = [];
                        this.listCustomerRecordData = listCustomerHistory;
                        this.back = true;
                        this.$modal.hide('customer-record-modal');
                    })
                    .catch((error) => {
                        console.log('error: ', error);
                        this.$swal(
                            this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                            '',
                            'warning'
                        );
                    });
            }
        },

        showEditCustomerRecordModal(timestamp, groupName, recordID, customerID, image, avatar, name) {
            this.timestampRecord = timestamp;
            this.recordID = recordID;
            this.customerIDInRecord = customerID;
            this.customerNameInRecord = name;
            this.customerAvatarInRecord = avatar;
            this.groupName = groupName;
            localStorage.setItem(
                'currentCustomerRecordData',
                JSON.stringify({
                    recordID: recordID,
                    customerID: customerID,
                    groupName: groupName,
                    timestampRecord: timestamp,
                })
            );
            const self = this;
            this.newCustomerModal = avatar === '/img/default-avatar.png';
            const lang = localStorage.lang;
            getCustomerRecordDetail
                .get(customerID, lang, recordID)
                .then((response) => {
                    if (response.data.customerType) {
                        const selectedCustomerType = this.listCustomerType.find(
                            (customerType) => customerType.id === response.data.customerType
                        );
                        self.customerTypeTitleInModal = selectedCustomerType.name;
                    } else {
                        self.customerTypeTitleInModal = this.$t('CustomerReport.NoSegment');
                    }

                    self.customerNameInModal = response.data.name;
                    if (response.data.birthDate == null) {
                        self.customerAgeInModal = null;
                    } else {
                        self.customerAgeInModal = response.data.birthDate.split('/').join('/');
                    }

                    self.customerTelephoneInModal = response.data['telephone'];
                    self.customerImageInModal = response.data.historyImage;
                    self.customerVisitNumberInModal = response.data.visitNumber;

                    if (response.data.gender === 'male') {
                        self.genderTitleInModal = self.$t('dashboard.Male');
                    } else if (response.data.gender === 'female') {
                        self.genderTitleInModal = self.$t('dashboard.Female');
                    } else {
                        self.genderTitleInModal = self.$t('Customers.TypeGender');
                    }

                    // self.editingCustomer = true;
                    self.$modal.show('customer-record-modal');
                })
                .then(() => {
                    fetch(this.customerImageInModal)
                        .then((res) => res.blob())
                        .then((blob) => {
                            this.sendingImage = new File([blob], 'image.jpg', {
                                type: 'image/jpg',
                            });
                        });
                })
                .catch((error) => {
                    getCustomerRecordDetail
                        .get(dateStringOfCustomerRecord.unix(), groupName, recordID)
                        .then((response) => {
                            if (response.data.response.customerType !== '') {
                                self.customerTypeTitleInModal = response.data.response.customerType;
                            }
                            if (response.data.response.customerType === '') {
                                self.customerTypeTitleInModal = this.$t('CustomerReport.NoSegment');
                            }

                            self.customerNameInModal = response.data.response.customerName;
                            if (response.data.response.birthDate == null) {
                                self.customerAgeInModal = null;
                            } else {
                                self.customerAgeInModal = response.data.response.birthDate
                                    .split('/')
                                    .reverse()
                                    .join('/');
                            }

                            self.customerTelephoneInModal = response.data.response['telephoneNumber'];
                            self.customerImageInModal = response.data.response.currentImage;
                            self.customerVisitNumberInModal = response.data.response.visitNumber;

                            if (response.data.response.gender === 'male') {
                                self.genderTitleInModal = self.$t('dashboard.Male');
                            } else if (response.data.response.gender === 'female') {
                                self.genderTitleInModal = self.$t('dashboard.Female');
                            } else {
                                self.genderTitleInModal = self.$t('Customers.TypeGender');
                            }

                            // self.editingCustomer = true;
                            self.$modal.show('customer-record-modal');
                        });
                });
        },

        closeModal() {
            this.$modal.hide('customer-record-modal');
        },
    },
    mounted() {
        if (!Boolean(this.$route.query.meta) && !Boolean(this.$route.query.report)) {
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                this.searchValue,
                this.sortAtribute,
                this.ascendingTimeValue,
                this.customerTypeArr.toString(),
                this.groupNameFilter,
                this.page,
                this.limit,
                this.filterIsMaskedFace,
                'false'
            );
        }
        this.datePickerLang = localStorage.lang;
        if (this.$route.query.meta) {
            this.isBehavior = this.$route.query.meta;
        }
        if (this.$route.query.report) {
            this.isBehavior = this.$route.query.report;
        }
        getAllGroupCurrentUser.get().then((response) => {
            response?.data?.listResponse.push({ id: '', name: localStorage.lang === 'vn' ? 'Tất cả' : 'All' });
            this.listGroup = response.data['listResponse'];
        });
        listCustomerTypeAPI.get().then((response) => {
            for (const data of response.data['listCustomerTypeResponse']) {
                if (localStorage.lang !== 'vn' && data['name'] === 'Chưa có phân khúc') data['name'] = 'No Segment';
                this.listCustomerType.push(data);
            }
        });
    },
    data() {
        let self = this;
        return {
            specifiedCustomerHistory: false,
            backToListCustomer: false,
            sendingImage: '',
            listCustomerType: [],
            listCustomerRecordData: [],
            back: false,
            totalPage: 0,
            filterMode: false,
            showRangePicker: false,
            rangeTimeString: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            rangeTime: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            ascendingTimeValue: false,
            dateStart: moment().startOf('day').format('X'),
            dateEnd: moment().startOf('day').format('X'),
            searchValue: '',
            sortAtribute: 'timestamp',
            customerTypeFilter: '',
            groupNameFilter: '',
            page: 1,
            limit: 5,
            recordID: '',
            timestampRecord: '',
            groupName: '',
            customerNameInRecord: '',
            customerAvatarInRecord: '',
            customerIDInRecord: '',
            datePickerLang: '',

            genderTitleInModal: self.$t('Customers.TypeGender'),
            customerTypeTitleInModal: self.$t('Customers.TypeCustomerType'),
            listGroup: [],
            groupSelectd: '',
            customerTypeSelected: '',

            groupFilterResult: self.$t('Report.StoreChoosing'),
            customerTypeFilterResult: self.$t('Customers.TypeCustomerType'),

            newCustomerModal: false,
            customerImageInModal: '',
            customerNameInModal: '',
            customerTelephoneInModal: '',
            customerAgeInModal: '',
            customerGenderInModal: '',
            customerTypeInModal: '',
            customerVisitNumberInModal: '',
            imageFile: '',

            storeAvailable: false,
            segmentAvailable: false,
            isBehavior: false,
            filterIsMaskedFace: null,
            showCustomerHistoryModal: false,
            customerTypeArr: [],
            groupNameArr: [],

            customerTypeDisplay: '',
            groupDisplay: '',
        };
    },
    watch: {
        isBehavior(val) {
            this.specifiedCustomerHistory = !val;
            if (this.specifiedCustomerHistory === false) {
                if (this.$route.query.meta === val) {
                    this.callBackBehavior(val);
                }
                if (this.$route.query.report === val) {
                    this.callBackBehavior(val);
                }
            }
        },
        limit(val) {
            if (this.back === false) {
                this.page = 1;
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    this.searchValue,
                    this.sortAtribute,
                    this.ascendingTimeValue,
                    this.customerTypeArr.toString(),
                    this.groupNameFilter,
                    this.page,
                    this.limit,
                    this.filterIsMaskedFace,
                    'false'
                );
                this.$refs['wrapLimitFilterOption'].style.display = 'none';
                this.$refs['wrapLimitFilterOption'].style.visibility = 'hidden';
            } else {
                customerHistoryAPI
                    .get(
                        Boolean(this.$route.query.cusID) ? this.$route.query.cusID : this.customerIDInRecord,
                        this.page,
                        this.limit
                    )
                    .then((response) => {
                        let listCustomerHistory = [];
                        this.$refs['wrapLimitFilterOption'].style.display = 'none';
                        this.$refs['wrapLimitFilterOption'].style.visibility = 'hidden';
                        for (let index = 0; index < response.data.historyData.length; index++) {
                            let record = {
                                currentImage: response.data.historyData[index].currentImage,
                                image: response.data.historyData[index].image,
                                customerName: response.data.historyData[index].customerName,
                                date: response.data.historyData[index].date,
                                groupName: response.data.historyData[index].groupName,
                                customerType: response.data.historyData[index].customerType,
                                timeVisit: response?.data?.historyData[index]?.timeVisit,
                            };
                            listCustomerHistory.push(record);
                        }
                        this.totalPage = response.data.totalPage;
                        this.listCustomerRecordData = listCustomerHistory;
                    })
                    .catch((error) => {
                        this.$swal(
                            this.$t('CustomerResponse.' + error.response.data.message.replace('.', '')),
                            '',
                            'warning'
                        );
                    });
            }
        },
        rangeTime(val) {
            if (val[0] === null && val[1] === null) {
                this.rangeTime = [moment().format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')];
                this.rangeTimeString = [moment().format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')];
            }
            if (val.length !== 0) {
                this.page = 1;
                this.specifiedCustomerHistory = false;
                if (val[0] !== null) {
                    let dS = val[0].toString().includes('-') ? val[0].split('-').reverse().join('-') : val[0];
                    this.dateStart = moment(dS).startOf('day').format('X');
                } else {
                    this.dateStart = moment().startOf('day').format('X');
                }
                if (val[1] !== null) {
                    let dE = val[1].toString().includes('-') ? val[1].split('-').reverse().join('-') : val[1];
                    this.dateEnd = moment(dE).startOf('day').format('X');
                } else {
                    this.dateEnd = moment().startOf('day').format('X');
                }

                this.specifiedCustomerHistory = false;
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    this.searchValue,
                    this.sortAtribute,
                    this.ascendingTimeValue,
                    this.customerTypeArr.toString(),
                    this.groupNameFilter,
                    this.page,
                    this.limit,
                    this.filterIsMaskedFace,
                    'false'
                );
                // }
                this.showRangePicker = false;
            }
        },
        filterIsMaskedFace(val) {
            if (val === true) {
                this.page = 1;
                this.filterIsMaskedFace = val;
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    this.searchValue,
                    this.sortAtribute,
                    this.ascendingTimeValue,
                    this.customerTypeArr.toString(),
                    this.groupNameFilter,
                    this.page,
                    this.limit,
                    this.filterIsMaskedFace,
                    'false'
                );
            } else {
                this.page = 1;
                this.filterIsMaskedFace = val;
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    this.searchValue,
                    this.sortAtribute,
                    this.ascendingTimeValue,
                    this.customerTypeArr.toString(),
                    this.groupNameFilter,
                    this.page,
                    this.limit,
                    this.filterIsMaskedFace,
                    'false'
                );
            }
        },
    },
};
</script>

<style lang="scss">
.HeaderGuess {
    display: flex;
    justify-content: space-between;

    .HeaderGuess-title {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: #1f1f1f;
    }

    .HeaderGuess-actions {
        display: flex;

        .header-img {
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;

            img {
                width: 100px;
                margin-bottom: 16px;
                height: 100px;
            }
        }

        .sectionPogup {
            width: 100%;
            padding: 24px;

            .wrapSelectNamePhone {
                display: flex;
                justify-content: space-between;

                .SelectName {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 12px);
                }

                .SelectPhone {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 12px);

                    .mx-datepicker {
                        height: 48px;
                        border: 1px solid rgba(86, 103, 137, 0.26);
                        box-sizing: border-box;
                        border-radius: 8px;
                        width: 100% !important;
                        display: flex;
                        align-items: center;

                        .mx-input-wrapper {
                            height: 100% !important;
                            width: 100% !important;
                            border-radius: 8px;

                            .mx-input {
                                height: 100% !important;
                                width: 100% !important;
                                border-radius: 8px;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        .BottomPogupReport {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 24px;

            .Btn-Close {
                padding: 6px 16px;
                width: 104px;
                height: 42px;
                background: #f0f0f0;
                border-radius: 8px;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            .Btn-Save {
                padding: 6px 16px;
                width: 104px;
                height: 42px;
                background: #276eff;
                border-radius: 8px;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }

        .linkSeeHistory {
            padding: 0px 24px 16px;
            width: 100%;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #107c10;
            cursor: pointer;
        }

        .btn-show {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #276eff;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 178px;
            height: 56px;
            padding: 16px 24px;
            border: 1px solid #276eff;
            box-sizing: border-box;
            border-radius: 8px;
        }

        .btn-show-type {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #276eff;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 256px;
            height: 56px;
            padding: 16px 24px;
            border: 1px solid #276eff;
            box-sizing: border-box;
            border-radius: 8px;
            margin-right: 16px;
        }

        .btn-export-csv {
            height: 80%;
            padding: 10px;
            font-size: 14px;
            background-color: #4476d9;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.WrapSectionHistory {
    padding: 24px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 32px;

    .WrapBtn {
        display: flex;

        .mx-input {
            padding: 16px 24px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            margin-right: 16px;
            margin-left: 16px;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #1f1f1f;
            cursor: pointer;

            &::placeholder {
                color: #bfbfbf;
            }
        }

        .btn-search {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            width: 256px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            color: #bfbfbf;
            cursor: pointer;

            .material-icons {
                color: #8c8c8c;
            }

            input {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1f1f1f;
                width: 100%;
                height: 54px;
                border: 0px solid #fff;

                &::placeholder {
                    color: #bfbfbf;
                }
            }
        }

        .btn-filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #1f1f1f;
            color: #5a6170;
            cursor: pointer;

            .material-icons {
                color: #8c8c8c;
            }
        }

        .btn-delete {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            height: 56px;
            box-sizing: border-box;
            border-radius: 8px;
            color: #ff4343;
            cursor: pointer;
        }
    }

    .SelectFilter {
        width: 100%;
        margin-top: 24px;
        display: flex;
        cursor: pointer;
    }

    .WrapTableCustomerHistory {
        margin-top: 24px;
        border: solid 1px #f0f0f0;

        table {
            border-spacing: 0 !important;
            width: 100%;
        }

        .tr-head {
            height: 80px;
            background-color: #064b6c;
            color: #fff;

            th:first-child {
                width: 258px;
            }

            th {
                .material-icons {
                    vertical-align: middle;
                }
            }
        }

        td {
            text-align: center;
            height: 64px;
            padding: 8px 12px;
            font-family: 'Roboto';
            font-size: 14px;
            color: #272833;
            font-weight: 400;

            img {
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }
        }

        .border-bottom {
            border-bottom: solid 1px #f0f0f0;
        }

        .border-left {
            border-left: solid 1px #f0f0f0;
        }

        .border-right {
            border-right: solid 1px #f0f0f0;
        }

        td:first-child {
            min-width: 200px;
            cursor: pointer;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: '...';
        }

        td:last-child {
            .material-icons {
                cursor: pointer;
            }
        }

        td:nth-child(5) {
            max-width: 64px !important;
        }

        .pagination {
            display: inline-block;
            border-radius: 5px;
            display: flex;
            justify-content: flex-end;

            .wrapSelectPagination {
                display: flex;
                align-items: center;
                position: relative;

                .seeTable {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: 'Roboto';
                }

                .SelectPagination {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 60px;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: 'Roboto';
                    padding: 8px 8px;
                    cursor: pointer;
                    border-radius: 8px;
                    margin-left: 16px;

                    // &:hover {
                    //     background-color: #F6F9FF;
                    //     .wrapOptionPagination {
                    //         display: block;
                    //     }
                    // }
                    .wrapOptionPagination {
                        position: absolute;
                        display: none;
                        padding: 8px;
                        width: 60px;
                        background: #ffffff;
                        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
                        border-radius: 10px;
                        top: 40px;
                        right: 0;
                        z-index: 9;

                        .Option {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px 16px;
                            width: 100%;
                            height: 36px;
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            cursor: pointer;
                        }

                        .OptionActive {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px 16px;
                            width: 100%;
                            height: auto;
                            font-family: Roboto;
                            background-color: #edf3ff;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .pagination a {
            color: black;
            float: left;
            padding: 12px 16px;
            text-decoration: none;
        }

        .pagination a.active {
            color: #2b4bf2;
            border-radius: 5px;
        }

        .pagination a:hover:not(.active) {
            background-color: #f6f9ff;
            border-radius: 5px;
            color: #fff;
        }
    }
}

.selectCustom {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    width: 212px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5a6170;
    border: 1px solid #8c8c8c;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    .wrapOption {
        position: absolute;
        display: none;
        padding: 8px;
        width: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 40px;
        left: -2px;
        z-index: 9999;

        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            hheight: auto;
        }

        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }

        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }

    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
}

.CloserFilter {
    margin-left: 16px;
    height: 40;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CloserFilter2 {
    margin-left: 16px;
    height: 40;
    width: 150px;
    display: flex;
    //justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}

.mr-16 {
    margin-right: 16px;
}

.ml-16 {
    margin-left: 16px;
}

.ml-32 {
    margin-left: 32px;
}

.ml-auto {
    margin-left: auto;
}

.LabelInput {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #2b4bf2;
}

.InputCustomPogup {
    height: 48px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;

    &::placeholder {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8c8c8c;
    }
}

.SelectCustomPogup {
    height: 48px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    cursor: pointer;
    position: relative;

    .wrapOption {
        position: absolute;
        z-index: 999999;
        display: none;
        padding: 8px;
        width: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 50px;
        left: -2px;

        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }

        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }

    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
}

.mt-8px {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.width100 {
    width: 100% !important;
}

.width156 {
    width: 156px;
}

.widthauto {
    width: auto;
}

.ActiveButton {
    border: 1px solid #276eff !important;
    color: #276eff !important;

    .material-icons {
        color: #276eff !important;
    }
}
</style>